import React, { Component } from 'react'
import { Link } from "react-router-dom";
import '../Styles/order-page.scss'
import { FormControl, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

class OrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '', number: '', email: '', deliveryType: 'До адрес', city: '', address: '', additionalInfo: '',
            notValid: {
                name: false, number: false, email: false, deliveryType: false, city: false, address: false
            },
            isValid: false,
            touched: false,
        }
        this.setValue = this.setValue.bind(this);
        this.onComplete = this.onComplete.bind(this);
    }

    setValue(event, key) {
        event.preventDefault();
        event.stopPropagation();

        const cpy = { ...this.state.notValid };
        let requiredKeys = ["name", "number", "email", "city", "address"];
        requiredKeys = requiredKeys.filter(k => {
            return key !== k && this.state[k] === '';
        });

        const newInputValue = event.target.value;
        let isInvalid = newInputValue === '';
        if (key === 'email') {
            const reg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/gi;
            if (!reg.test(newInputValue)) {
                isInvalid = true;
            }
        }

        cpy[key] = isInvalid;

        const isValid = requiredKeys.length === 0 && Object.values(cpy).reduce((oldVal, newVal) => oldVal && !newVal, true);

        this.setState({
            [key]: newInputValue,
            notValid: cpy,
            touched: true,
            isValid: isValid
        });
    }

    onComplete() {
        this.props.saveShipingData({
            name: this.state.name,
            number: this.state.number,
            email: this.state.email,
            deliveryType: this.state.deliveryType,
            city: this.state.city,
            address: this.state.address,
            additionalInfo: this.state.additionalInfo
        });
    }

    render() {
        return (
            <div className="order-page">
                <h1>Данни за доставка</h1>
                <p>Моля попълнете формуляра. Наш служител ще се свърже с вас за потвърждение.</p>
                <FormControl fullWidth className="formControl">
                    <TextField
                        type="text"
                        value={this.state.name}
                        name="name"
                        label="Име и Фамилия"
                        variant="standard"
                        required
                        error={this.state.notValid.name}
                        onChange={(e) =>  this.setValue(e, 'name')}
                    />
                    <TextField
                        type="number"
                        value={this.state.number}
                        name="number"
                        label="Телефонен номер"
                        variant="standard"
                        required
                        error={this.state.notValid.number}
                        onChange={(e) => this.setValue(e, 'number')}
                    />
                    <TextField
                        type="email"
                        value={this.state.email}
                        name="email"
                        label="Емейл"
                        variant="standard"
                        required
                        error={this.state.notValid.email}
                        onChange={(e) => this.setValue(e, 'email')}
                    />
                    <FormLabel id="demo-row-radio-buttons-group-label" error={this.state.notValid.deliveryType}>Начин на доставка</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e) => this.setValue(e, 'deliveryType')}
                        required
                        value={this.state.deliveryType}
                    >
                        <FormControlLabel value="До адрес" control={<Radio />} label="До адрес" />
                        <FormControlLabel value="До офис на Еконт" control={<Radio />} label="До офис на Еконт" />
                        <FormControlLabel value="До офис на Спиди" control={<Radio />} label="До офис на Спиди" />
                    </RadioGroup>
                    {(this.state.deliveryType === 'До офис на Еконт' || this.state.deliveryType === 'До офис на Спиди') && <div>
                        Моля, въведете адреса на офиса.
                    </div>}
                    <TextField
                        type="text"
                        value={this.state.city}
                        name="city"
                        label="Град"
                        variant="standard"
                        required
                        error={this.state.notValid.city}
                        onChange={(e) => this.setValue(e, 'city')}
                    />
                    <TextField
                        type="text"
                        value={this.state.address}
                        name="address"
                        label={this.state.deliveryType === 'До адрес' ? "Aдрес (улица, номер, блок, вход)" : 'Адрес (офис на куриер)'}
                        variant="standard"
                        required
                        error={this.state.notValid.address}
                        onChange={(e) => this.setValue(e, 'address')}
                    />
                    <TextField
                        type="text"
                        value={this.state.additionalInfo}
                        name="additionalInfo"
                        label="Допълнителна информация"
                        variant="standard"
                        onChange={(e) => this.setValue(e, 'additionalInfo')}
                    />
                    <Link to="/confirm" disabled={!this.state.isValid}><button className="button" onClick={this.onComplete} disabled={!this.state.isValid}>Завърши</button></Link>
                </FormControl>
            </div>
        )
    }
}

export default OrderPage;