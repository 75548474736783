import React, { Component } from 'react'
import './Carousel.scss'
import { gsap } from 'gsap';
import { Link } from "react-router-dom";

export default class Carousel extends Component {
    constructor(props) {
        super(props);
        this.padEl = React.createRef();
        this.slideEl = React.createRef();
        this.slide = this.slide.bind(this);
        this.fixDelayedCall = this.fixDelayedCall.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.timeout = null;
        this.debounce = false;
        this.state = {
            position: 'right',
            page: 1,
            pageCount: this.props.data.length,
            touchX: null
        };
    }

    slide(event, newPage) {
        if (!this.debounce) {
            this.debounce = true;
            setTimeout(() => {
                this.debounce = false;
            }, 800);

            if (event) {
                this.timeout = true;
            }
            const items = [...this.slideEl.current.getElementsByClassName('Carousel-slide')];
            gsap.to(items, { duration: 0.75, xPercent: `+=${(this.state.page - newPage) * 100}`, ease: 'power2.inOut' })
            this.setState({ page: newPage });
        }
    }

    componentDidMount() {
        this.fixDelayedCall();
    }

    fixDelayedCall() {
        gsap.delayedCall(4.5, () => {
            if (!this.timeout) {
                if (this.state.page + 1 < this.state.pageCount) {
                    this.fixDelayedCall();
                }
                this.slide(null, this.state.page + 1);
            }
        })
    }

    handleTouchStart(e) {
        const touchDown = e.touches[0].clientX;
        this.setState({ touchX: touchDown });
    }

    handleTouchMove(e) {
        const touchDown = this.state.touchX;

        if(touchDown === null) {
            return;
        }
    
        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > 5 && this.state.page < this.state.pageCount) {
            this.slide(e, this.state.page + 1);
        }
    
        if (diff < -5 && this.state.page > 1) {
            this.slide(e, this.state.page - 1);
        }
    }

    handleTouchEnd() {
        this.setState({ touchX: null });
    }

    componentWillUnmount() {
        this.timeout = true;
    }

    render() {
        return this.props.data && (
            <div className={`Carousel type-${this.props.type || 'left'}`} ref={this.slideEl}
            onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
            onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
            onTouchEnd={() => this.handleTouchEnd()}>
                {this.props.data.map(d => {
                    return (<div className='Carousel-slide' key={d.id}>
                        <Link to={d.url}>
                            <div className="Carousel-pad">
                                <div className="Carousel-pad-fill">
                                </div>
                                <div className="Carousel-content">
                                    <div className="Carousel-content-inner Carousel-content-left">
                                        <h1>{d.header}</h1>
                                        <p>{d.paragraph}</p>
                                        {
                                            d.url &&
                                        <button className='button'>{d.button}</button>
                                        }
                                    </div>
                                </div>
                            </div>

                            <img src={d.image} alt="nqkuv bg" className="leftImage" />
                            </Link>
                    </div>
                   )
                })}

                <div className={`Carousel-controls ${this.state.position}`}>
                    {[...Array(this.state.pageCount).keys()].map((i) => {
                        return <div className={`dot dot-${i} ${i + 1 === this.state.page ? 'active': ''}`} key={i} onClick={(e) => this.slide(e, i + 1)}></div>
                    })}
                </div>

            </div>
        )
    }
}