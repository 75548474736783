import React, { Component } from 'react'
import { withRouter, Link } from "react-router-dom";
import '../Styles/successful-page.scss'

class SuccessfulPage extends Component {
    render() {
        return (
            <div className="successful-page" >
                <h1>Успешно направена поръчка!</h1>
                <p>Наш служител ще се свърже с вас на посочения телефон, за да потвърди поръчката.</p>
                <Link to="/"><div className="button">Начало</div></Link>
                <p className='quote'>"<i>Времето ви е ограничено. Затова не го губете, живейки живота на някой друг.</i>" - <b>Стийв Джобс</b></p>
            </div>
        )
    }
}

export default withRouter(SuccessfulPage);