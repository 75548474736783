import React, { Component } from 'react'
import '../Styles/article-page.scss'
import ReactMarkdown from 'react-markdown'
import { Helmet } from "react-helmet";

export default class ArticlePage extends Component {
    constructor(props) {
        super(props);
        this.state = { data: null }
        this.id = this.props.match.params.id;
    }

    componentDidMount() {
        fetch("/articles.json")
            .then(res => res.json())
            .then(
                (result) => {
                    const f = result.filter(a => a.id === this.id)[0] || {};
                    const data = f.paragraphs.reduce((acc, c) => acc + '\n' + c, '\n');
                    this.setState({
                        data: data
                    });
                },
                (error) => {
                    console.warn(error);
                }
            )
    }

    render() {
        return this.state.data && (
            <div className="article-page">
                <Helmet>
                    <meta name="og:image" content={this.state.data.image}/>
                </Helmet>

                {/* <ReactMarkdown># Hello, *world*!</ReactMarkdown> */}
                {/* <img src={this.state.data.image} alt="asd" /> */}
                {/* <h1>{this.state.data.header}</h1> */}
                {/* {this.state.data.paragraphs.map((p, index) => { */}
                    {/* // return <p key={index}>{p}</p>; */}
                    {/* return  */}
                {/* // })} */}
                <ReactMarkdown>{this.state.data}</ReactMarkdown>
            </div>
        )
    }
}