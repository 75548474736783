import React, { Component } from 'react'
import '../Styles/home.scss'
import Carousel from '../Components/Carousel/Carousel'
import ArticleList from '../Components/ArticleList/ArticleList'

export default class HomePage extends Component {
    constructor(props) {
        super(props);
        this.topAnchor = React.createRef();
        this.bottomAnchor = React.createRef();
        this.mainDiv = React.createRef();
        this.state = {
            shoesData: null,
            insolesData: null
        }
    }

    componentDidMount() {
        fetch("/carousel_1.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        shoesData: result
                    });
                },
                (error) => {
                    console.warn(error);
                }
            )

        fetch("/carousel_2.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        insolesData: result
                    });
                },
                (error) => {
                    console.warn(error);
                }
            )
    }

    render() {
        return this.state.shoesData && this.state.insolesData && (
            <div className="home-component" ref={this.mainDiv}>
                <div id="topAnchor" ref={this.topAnchor}></div>
                <Carousel data={this.state.shoesData}/>
                <div id="bottomAnchor" ref={this.bottomAnchor}></div>
                <div className='textContent'>
                    <ArticleList count={3}/>
                </div>
                {/* <Carousel data={this.state.insolesData} type="right"/> */}
            </div>
        )
    }
}
