import Layout from "./Components/Layout/Layout";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>BoyarShoe</title>
      </Helmet>
      <Layout />
    </div>
  );
}

export default App;
