import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import HomePage from "../../Pages/Home";
import ArticlesPage from "../../Pages/Articles";
import ArticlePage from "../../Pages/Article";
import ShoesPage from "../../Pages/Shoes";
import ShoePage from "../../Pages/Shoe";
import OrderPage from "../../Pages/Order";
import ConfirmPage from "../../Pages/Confirm";
import CartPage from "../../Pages/Cart";
import FaqPage from "../../Pages/Faq";
import './Layout.scss'
import SuccessfulPage from '../../Pages/Successful';

export default class Layout extends Component {
    constructor(props) {
        super(props);
        const sessionData = JSON.parse(sessionStorage.getItem('orderData') || "[]");
        const orderId = sessionStorage.getItem('orderLastId') - 0 || 0;
        
        this.state = {
            cartData: sessionData,
            orderId: orderId,
            shipingData: null,
            triggerCart: false
        }

        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.saveShipingData = this.saveShipingData.bind(this);
        this.clearOrder = this.clearOrder.bind(this);
        this.triggerCartFunc = this.triggerCartFunc.bind(this);
    }
    
    addItem(obj) {
        const cpy = [...this.state.cartData];
        const orderId = this.state.orderId;
        cpy.push({ orderId, ...obj});
        this.setState({
            cartData: cpy,
            orderId: this.state.orderId + 1
        });

        sessionStorage.setItem('orderData', JSON.stringify(cpy));
        sessionStorage.setItem('orderLastId', orderId + 1);
    }

    removeItem(orderId) {
        const filter = [...this.state.cartData].filter(o => {
            return o.orderId !== orderId;
        });
        this.setState({
            cartData: filter
        });
        sessionStorage.setItem('orderData', JSON.stringify(filter));
    }

    saveShipingData(data) {
        this.setState({
            shipingData: data
        });
    }

    clearOrder() {
        this.setState({
            cartData: []
        });
        sessionStorage.setItem('orderData', JSON.stringify([]));
    }

    triggerCartFunc() {
        this.setState({ triggerCart: true });

        setTimeout(() => {
            this.setState({ triggerCart: false });
        }, 3000);
    }

    render() {
        return (
            <div id="mainWrapper">
                <BrowserRouter>
                    <Header cartData={this.state.cartData} removeItem={this.removeItem} triggerCart={this.state.triggerCart}></Header>
    
                    <div className="content-container">
                        <Switch>
                            <Route path="/articles">
                                <ArticlesPage />
                            </Route>
                            <Route path="/article/:id" component={ArticlePage} />
                            <Route path="/shoes">
                                <ShoesPage />
                            </Route>
                            <Route path="/shoe/:id">
                                <ShoePage addItem={this.addItem} triggerCartFunc={this.triggerCartFunc}/>
                            </Route>
                            <Route path="/cart">
                                <CartPage cartData={this.state.cartData} removeItem={this.removeItem}/>
                            </Route>
                            <Route path="/order">
                                <OrderPage saveShipingData={this.saveShipingData} />
                            </Route>
                            <Route path="/confirm">
                                <ConfirmPage cartData={this.state.cartData} shipingData={this.state.shipingData} clearOrder={this.clearOrder} />
                            </Route>
                            <Route path="/faq">
                                <FaqPage cartData={this.state.cartData} removeItem={this.removeItem}/>
                            </Route>
                            <Route path="/successful">
                                <SuccessfulPage />
                            </Route>
                            <Route path="/">
                                <HomePage />
                            </Route>
                        </Switch>
                    </div>
    
                    <Footer></Footer>
                </BrowserRouter>
            </div>
        );
    }
}