import React, { Component } from 'react'
import './Header.scss'
import { Link, withRouter } from "react-router-dom";
import Cart from '../Cart/Cart'

class Header extends Component {
    constructor(props) {
        super(props);
        this.showCart = this.showCart.bind(this);
        this.state = {
            showCart: false,
            lastLength: this.props.cartData.length,
            interaction: false
        }
        this.sandwich = React.createRef();
        this.handleCrossClick = this.handleCrossClick.bind(this);
    }

    componentDidUpdate() {
        if (this.state.lastLength !== this.props.cartData.length) {
            this.setState({ lastLength: this.props.cartData.length });
        }

        if (this.props.triggerCart !== this.state.showCart && !this.state.interaction) {
            this.setState({ showCart: this.props.triggerCart });
        }
    }

    showCart(val) {
        this.setState({ interaction: val, showCart: val });
    }

    handleCrossClick(type) {
        if (type === 'toggle') {
            this.sandwich.current.classList.toggle("change")
        } else if (type === 'remove') {
            this.sandwich.current.classList.remove("change")
        }
    }

    render() {
        let links = [];
        // links.push({
        //     href: "/", text: "Начало", onClick: (e) => {
        //         e.preventDefault();
        //         gsap.to('.content-container > *', { duration: 0.5, opacity: 0 })
        //         setTimeout(() => {
        //             this.props.history.push('/');
        //         }, 500);
        //     }, icon: "fas fa-home"
        // });
        // links.push({ href: "/shoes", text: "Обувки", icon: 'fas fa-shoe-prints' });
        // links.push({ href: "/insole", text: "Стелки", icon: "fas fa-sort-amount-up-alt" });
        links.push({ href: "/", text: "Начало", icon: "fa fa-home" });
        links.push({ href: "/shoe/1", text: "Продукти", icon: "fa fa-shoe-prints" });
        links.push({
            href: "/articles", text: "Статии", icon: "fas fa-pen-square"
        });
        links.push({ href: "/faq", text: "FAQ", icon: "fas fa-question-circle" });
        // links.push({ href: "/cart", text: "Количка", icon: "fas fa-shopping-cart" });

        return (
            <div className="Header">
                <div className="Header-Left">
                    <Link to='/' className="Header-Logo" onClick={() => this.handleCrossClick('remove')}>
                        <img className="Header-Logo" src="/boyar_shoe_logo_2.png" alt="site logo"></img>
                    </Link>
                    {/* <Link to='/' className="Header-Item"><img src="./../espa_logo.png" alt="" className="Header-Logo"></img></Link>
                    <Link className="Header-Item" to='/phones'>
                            <i className="icon fas fa-mobile"></i>Phones
                    </Link> */}
                </div>

                <div className="crossClick" onClick={() => this.handleCrossClick('toggle')} ref={this.sandwich}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>

                <div className="Header-Right">
                    {links.map((link) => {
                        return (
                            <Link className="Header-Item" to={link.href} key={link.href} onClick={() => this.handleCrossClick('remove')}>
                                <i className={link.icon}></i>{link.text}
                            </Link>)
                    })}

                    <Link className="Header-Item" to="/cart" key="/cart" 
                        onMouseOver={() => this.showCart(this.state.lastLength !== 0)}
                        onClick={() => this.handleCrossClick('remove')}>
                        <i className="fas fa-shopping-cart"></i><span className='ordersNumber'>{this.state.lastLength}</span>Количка
                    </Link>

                    {this.state.showCart && this.state.lastLength !== 0 && (
                        <div onMouseOver={() => this.showCart(this.state.lastLength !== 0)} onMouseLeave={() => this.showCart(false)}>
                            <Cart type="compact" cartData={this.props.cartData} removeItem={this.props.removeItem}></Cart>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(Header);