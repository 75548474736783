import React from 'react'
import { Link } from "react-router-dom";
import './Footer.scss';

export default function Footer() {
    return (
      <div className="Footer-wrapper">
          <a className="logo" href="/">
            <img src="/boyar_shoe_logo_2.png" alt=""></img>
          </a>

          <div className="container-ul-link">
            <ul>
              <li>Компания</li>
              {/* <li><Link to="/#about">За нас</Link></li> */}
              <li><Link to="/faq">FAQ</Link></li>
            </ul>

            <ul>
              <li>Продукти</li>
              <li><Link to="/shoe/1">Обувки</Link></li>
            </ul>

            <ul>
              <li>Социални мрежи</li>
              <li><a href="https://www.facebook.com/profile.php?id=100090936882764" target="_blank" rel="noreferrer">Facebook</a></li>
              <li><a href="https://www.instagram.com/boyarshoe" target="_blank" rel="noreferrer">Instagram</a></li>
            </ul>
          </div>
      </div>
    )
}