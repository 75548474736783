import React, { Component } from 'react'
import './ArticleList.scss'
import Article from '../Article/Article';

export default class ArticleList extends Component {
    constructor(props) {
        super(props);
        this.state = { data: null }
    }

    componentDidMount() {
        fetch("/articles.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        data: result
                    });
                },
                (error) => {
                    console.warn(error);
                }
            )
    }

    render() {
        return this.state.data && (
            <div className="ArticleList">
                {this.state.data.slice(0, this.props.count || this.state.data.length).map((a, index) => {
                    return <Article data={a} key={index} />
                })}
            </div>
        )
    }
}