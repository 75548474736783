import React, { Component } from 'react'
import './Article.scss'
import { Link } from "react-router-dom";

export default class Article extends Component {
    render() {
        const data = this.props.data;
        return (
            <div className="Article">
                <Link to={`/article/${data.id}`} key={`/article/${data.id}`}>
                    <img src={data.image} alt="article" />
                    <h3>{data.header}</h3>
                    {/* <p>{data.short_description}</p> */}
                    <button className="button">Прочети</button>
                </Link>
            </div>
        )
    }
}