import React, { Component } from 'react'
import '../Styles/shoe-page.scss'
import { FormControl, InputLabel, Select, MenuItem, TextField, Snackbar, Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { withRouter } from "react-router-dom";
import ReactMarkdown from 'react-markdown'

class ShoePage extends Component {
    constructor(props) {
        super(props);
        this.state = { data: null, quantity: 1, size: 40, mainImage: null, open: false, modalOpen: false, imageIndex: 0 }
        this.id = Number(this.props.match.params.id);
        this.setQuantity = this.setQuantity.bind(this);
        this.setSize = this.setSize.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.setMainImage = this.setMainImage.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setModalOpen = this.setModalOpen.bind(this);
        this.setModalClose = this.setModalClose.bind(this);
    }

    componentDidMount() {
        fetch("/shoes.json")
            .then(res => res.json())
            .then(
                (result) => {
                    const f = result.filter(s => s.id === this.id)[0] || {};
                    this.setState({
                        data: f,
                        mainImage: f.main_image
                    });
                },
                (error) => {
                    console.warn(error);
                }
            )
    }

    setOpen() {
        this.setState({ open: true });
    }
    
    handleClose (event, reason) {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ open: false });
    };

    setQuantity(event, dir) {
        event.preventDefault();
        event.stopPropagation();

        if (dir === 'plus') {
            this.setState({ quantity: Math.min(10, this.state.quantity - 0 + 1) });
        } else if (dir === 'minus') {
            this.setState({ quantity: this.state.quantity - 1 === 0 ? 1 : this.state.quantity - 1 });
        } else {
            const value = event.target.value;
            if (value > 0 && value <= 10) {
                this.setState({ quantity: event.target.value })
            }
        }
    }

    setSize(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ size: event.target.value });
    }

    handleClick(event) {
        event.preventDefault();
        event.stopPropagation();
        const obj = {
            'id': this.id,
            'type': 'shoe',
            'img': this.state.data.main_image,
            'title': this.state.data.header,
            'quantity': this.state.quantity,
            'price': this.state.data.price,
            'size': this.state.size
        }
        this.props.addItem(obj);
        this.setOpen();

        this.props.triggerCartFunc();
    }

    setMainImage(event, image, index) {
        event.preventDefault();

        if (image === null) {
            const length = this.state.data.images.length;
            let current = this.state.imageIndex + index < 0 ? length - 1 : this.state.imageIndex + index;
            current = current >= length ? 0 : current;
            this.setState({
                mainImage: this.state.data.images[current],
                imageIndex: current
            });

        } else {
            this.setState({
                mainImage: image,
                imageIndex: index
            });
        }
            
    }

    setModalOpen() {
        this.setState({ modalOpen: true });
    }

    setModalClose() {
        this.setState({ modalOpen: false });
    }

    render() {
        return this.state.data && (
            <div className="shoe-page">
                <div className="formWrapper">
                    <div className="imagesPreview">
                        <div className="mainImage">
                            <img src={this.state.mainImage} alt={`shoe img ${this.state.main_image}`} onClick={this.setModalOpen} />
                        </div>
                        <div className='allImages'>
                            {this.state.data.images.map((i, index) => {
                                return <div key={index}><img src={i} alt={`shoe ${index}`} onClick={(e) => this.setMainImage(e, i, index)}/></div>;
                            })}
                        </div>
                    </div>
                    <form action="">
                        <h3>{this.state.data.header}</h3>
                        <div className="input-group">
                            <button className="button button-minus" onClick={(e) => { this.setQuantity(e, 'minus') }}>
                                <i className="fas fa-minus"></i>
                            </button>
                            {/* <input type="number" disabled step="1" max="" value={this.state.quantity} name="quantity" className="quantity-field" onChange={this.setQuantity}></input> */}
                            <TextField
                                className="quantity-field"
                                type="number"
                                disabled
                                value={this.state.quantity}
                                name="quantity"
                                label="Количество"
                                variant="standard"
                                onChange={this.setQuantity}
                            />
                            <button className="button button-plus" onClick={(e) => this.setQuantity(e, 'plus')}>
                                <i className="fas fa-plus"></i>
                            </button>
                        </div>
                        <FormControl fullWidth className="input">
                            <InputLabel id="size">Размер</InputLabel>
                            <Select
                                labelId="size"
                                id="size"
                                value={this.state.size}
                                label="Размер"
                                onChange={this.setSize}
                            >
                                {this.state.data.sizes.map((s) => {
                                    return <MenuItem key={s} value={s}>{s}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <p className='price'>Цена:
                            <br />
                            <span className='oldPrice'>{this.state.data.old_price.toFixed(2)} лв.</span>
                            <span className='discount'>-{this.state.data.discount}%</span>
                            <br />
                            <span className='bigger'>{this.state.data.price.toFixed(2)} лв.</span> (+ безплатна доставка)    
                        </p>
                        <button className='button' onClick={this.handleClick}>Добави в количка</button>
                    </form>
                </div>
                {<ReactMarkdown>{this.state.data.paragraphs.reduce((acc, c) => acc + c, '\n')}</ReactMarkdown>}
                <Snackbar
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    className='notificationSnack'
                >
                    <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
                        Успешно добавен продукт!
                    </Alert>
                </Snackbar>

                <Modal
                    open={this.state.modalOpen}
                    onClose={this.setModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    classes={ { root: 'shoe-modal' }}
                    >
                    <Box>
                        <div className="imagesPreview">
                            <div className='prevImg' onClick={(e) => {this.setMainImage(e, null, -1)}}>&lt;</div>
                            <div className="mainImage">
                                <img src={this.state.mainImage} alt={`shoe img ${this.state.main_image}`} />
                            </div>
                            <div className='nextImg' onClick={(e) => {this.setMainImage(e, null, 1)}}>&gt;</div>
                            <div className='allImages'>
                                {this.state.data.images.map((i, index) => {
                                    return <div key={index}><img src={i} alt={`shoe ${index}`} onClick={(e) => this.setMainImage(e, i, index)}/></div>;
                                })}
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        )
    }
}

export default withRouter(ShoePage);