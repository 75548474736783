import React, { Component } from 'react'
import '../Styles/confirm-page.scss'
import Cart from '../Components/Cart/Cart'
import emailjs from '@emailjs/browser';
import { Snackbar, Alert } from '@mui/material';
import { withRouter } from "react-router-dom";
import Loader from '../Components/Loader/Loader'

class ConfirmPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipingData: props.shipingData || {},
            open: false,
            showLoader: false
        };
        this.send = this.send.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setLoader = this.setLoader.bind(this);
    }
    
    send(event) {
        event.preventDefault();

        this.setLoader(true);

        let timestamp = new Date();
        timestamp = timestamp.getTime();

        let order = `Благодарим, че избрахте BoyarShoe!\n\nПоръчка номер: ${timestamp}\n\n`;
        let totalPrice = 0;
        this.props.cartData.forEach((d) => {
            order += `${d.title}\n&emsp;Размер: ${d.size}\n&emsp;Количество: ${d.quantity}\n&emsp;Цена: ${d.quantity * d.price}лв\n\n`;
            totalPrice += d.quantity * d.price;
        })

        order += `Дължима сума: ${totalPrice}лв.\nТелефон: ${this.props.shipingData.number}\nНачин на доставка: ${this.props.shipingData.deliveryType}\nАдрес за доставка: ${this.props.shipingData.city}, ${this.props.shipingData.address}\nДопълнителна информация: ${this.props.shipingData.additionalInfo}\n`;
        this.setOpen();

        emailjs.send("service_urm0c3h","template_9sccltw",{
            to_name: this.state.shipingData.name,
            to_email: this.state.shipingData.email,
            order,
        }, '3ShJN4SB45oboJLmQ')
        .then((result) => {
            // console.log(result.text);
            // setTimeout(() => {
                this.setLoader(false);
                this.props.history.push('/successful');
                this.props.clearOrder();
            // }, 3000);
        }, (error) => {
            console.log(error.text);
        });
    }

    setOpen() {
        this.setState({ open: true });
    }
    
    handleClose (event, reason) {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ open: false });
    };

    setLoader(value) {
        this.setState({
            showLoader: value
        });
    }

    componentDidMount() {
        if (this.props.cartData.length === 0 || !this.props.shipingData) {
            this.props.history.push('/');
        }
    }    

    render() {
        return (
            <div className="confirm-page" >
                <h1>Завършване на поръчката</h1>
                <Cart type="full" cartData={this.props.cartData} removeItem={this.props.removeItem} removeBtn={true}></Cart>
                <h3>Данни за доставка</h3>
                <p><b>Име:</b> {this.state.shipingData.name}</p>
                <p><b>Телефон:</b> {this.state.shipingData.number}</p>
                <p><b>Емейл:</b> {this.state.shipingData.email}</p>
                <p><b>Начин на доставка:</b> {this.state.shipingData.deliveryType}</p>
                <p><b>Адрес за доставка:</b> {this.state.shipingData.city + ', ' + this.state.shipingData.address}</p>
                <p><b>Допълнителна информация:</b> {this.state.shipingData.additionalInfo}</p>
                <button className='button' onClick={(e) => this.send(e)}>Поръчай</button>

                <Snackbar
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    className='notificationSnack'
                >
                    <Alert onClose={this.handleClose} severity="success" sx={{ width: '100%' }}>
                        Успешна поръчка!
                    </Alert>
                </Snackbar>

                <Loader showLoader={this.state.showLoader}></Loader>
            </div>
        )
    }
}

export default withRouter(ConfirmPage);