import React, { Component } from 'react'
import './Loader.scss'

export default class Loader extends Component {
    render() {
        return (
            <div className={`Loader ${this.props.showLoader ? 'shown' : 'hidden'}`}>
                <div className="center">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
            </div>
        )
    }
}