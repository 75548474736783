import React, { Component } from 'react'
import './Cart.scss'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from "react-router-dom";

export default class Cart extends Component {
    constructor(props) {
        super(props);
        this.timeout = null;
        this.isCompact = props.type === 'compact';
        this.state = {
            type: props.type
        };
    }

    render() {
        return (
            <div className={`Cart ${this.state.type}`}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className='mobile'>Продукт</TableCell>
                                <TableCell></TableCell>
                                {!this.isCompact && <TableCell align="center" className='desktop'>Име</TableCell>}
                                <TableCell align="center" className='desktop'>Размер</TableCell>
                                <TableCell align="center" className='desktop'>Количество</TableCell>
                                {!this.isCompact && <TableCell align="center" className='desktop'>Единична цена</TableCell>}
                                <TableCell align="center" className='desktop'>Обща цена</TableCell>
                                {!this.props.removeBtn && <TableCell align="center">Премахни</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.cartData.map((o) => (
                                <TableRow
                                    key={o.orderId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left"><img src={o.img} alt={o.title} /></TableCell>
                                    {!this.isCompact && <TableCell align="center"  className='desktop'>{o.title}</TableCell>}
                                    {!this.isCompact && <TableCell align="center"  className='mobile'>{o.quantity}x <b>{o.title}</b> <br/> Размер: {o.size}<br/> Цена: {(o.price * o.quantity).toFixed(2)}лв.</TableCell>}
                                    <TableCell align="center" className='desktop'>{o.size}</TableCell>
                                    <TableCell align="center" className='desktop'>{o.quantity}</TableCell>
                                    {!this.isCompact && <TableCell align="center"  className='desktop'>{o.price.toFixed(2)} лв.</TableCell>}
                                    <TableCell align="center" className='desktop'>{(o.price * o.quantity).toFixed(2)} лв.</TableCell>
                                    {!this.props.removeBtn && <TableCell align="center" className="removeIcon" onClick={(e) => this.props.removeItem(o.orderId)}><i className="fas fa-times"></i></TableCell>}
                                </TableRow>
                            ))}

                            <TableRow>
                                <TableCell align="right" colSpan={this.isCompact ? 5 : 7}>Общо: {this.props.cartData.reduce(
                                    (previousValue, currentValue) => {
                                        return previousValue += currentValue.price * currentValue.quantity
                                    },
                                    0).toFixed(2)} лв.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {!this.isCompact && !this.props.removeBtn && <Link to="/order" disabled={this.props.cartData.length === 0}><button className="button" disabled={this.props.cartData.length === 0}>Поръчай</button></Link>}
                {this.isCompact && !this.props.removeBtn && <Link to="/cart"><button className="button">Към количката</button></Link>}
            </div>
        )
    }
}