import React, { Component } from 'react'
import '../Styles/articles.scss'
import ArticleList from '../Components/ArticleList/ArticleList'

export default class ArticlesPage extends Component {
    constructor(props) {
        super(props);
        this.mainDiv = React.createRef();
    }

    render() {
        return (
            <div className="articles-page" ref={this.mainDiv}>
                <h1>Статии</h1>
                <ArticleList rowSize="3" />
            </div>
        );
    }
}
