import React, { Component } from 'react'
import '../Styles/cart-page.scss'
import Cart from '../Components/Cart/Cart'

export default class CartPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shoesData: null,
            insolesData: null
        }
    }

    componentDidMount() {
        fetch("/carousel_1.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        shoesData: result
                    });
                },
                (error) => {
                    console.warn(error);
                }
            )
    }

    render() {
        return (
            <div className="cart-page" >
                <h1>Количка</h1>
                <Cart type="full" cartData={this.props.cartData} removeItem={this.props.removeItem}></Cart>
            </div>
        )
    }
}
