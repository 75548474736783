import React, { Component } from 'react'
import './Shoe.scss'
import { Link } from "react-router-dom";

export default class Shoe extends Component {
    render() {
        const data = this.props.data;
        return (
            <div className="Shoe">
                <Link to={`/shoe/${data.id}`} key={`/shoe/${data.id}`}>
                    <img src={data.image} alt="shoe" />
                    <h3>{data.header}</h3>
                    <p>{data.short_description}</p>
                    <button className="button">Разгледай</button>
                </Link>
            </div>
        )
    }
}