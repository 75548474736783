import React, { Component } from 'react'
import './ShoesList.scss'
import Shoe from '../Shoe/Shoe';

export default class ShoesList extends Component {
    constructor(props) {
        super(props);
        this.state = { data: null }
    }

    componentDidMount() {
        fetch("/shoes.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        data: result
                    });
                },
                (error) => {
                    console.warn(error);
                }
            )
    }

    render() {
        return this.state.data && (
            <div className="ShoesList">
                {this.state.data.slice(0, this.props.count || this.state.data.length).map((a, index) => {
                    return <Shoe data={a} key={index} />
                })}
            </div>
        )
    }
}