// import React, { Component } from 'react'
// import '../Styles/shoes.scss'
// import { Link } from "react-router-dom";

// export default class ShoesPage extends Component {
//     constructor(props) {
//         super(props);
//         this.state = { data: null }
//     }

//     componentDidMount() {
//         fetch("/shoes.json")
//             .then(res => res.json())
//             .then(
//                 (result) => {
//                     this.setState({
//                         data: result
//                     });
//                 },
//                 (error) => {
//                     console.warn(error);
//                 }
//             )
//     }

//     render() {
//         return this.state.data && (
//             <div className="shoes-page">
//                 <h1>Обувки</h1>
//                 <div className="shoes-wrapper">
//                     {this.state.data.slice(0, this.props.count || this.state.data.length).map((a, index) => {
//                         return (
//                             <div className='shoe'>
//                                 <Link to={`/shoe/${a.id}`} key={`/shoe/${a.id}`}>
//                                     <img src={a.image} alt="shoe" />
//                                     <h3>{a.header}</h3>
//                                     <p>{a.short_description}</p>
//                                     <button className="button">Разгледай</button>
//                                 </Link>
//                             </div>
//                         )
//                     })}
//                 </div>
//             </div>
//         )
//     }
// }

import React, { Component } from 'react'
import '../Styles/shoes.scss'
import ShoesList from '../Components/ShoesList/ShoesList'

export default class ShoesPage extends Component {
    constructor(props) {
        super(props);
        this.mainDiv = React.createRef();
    }

    render() {
        return (
            <div className="shoes-page" ref={this.mainDiv}>
                {/* <h1>Shoes</h1> */}
                <ShoesList />
            </div>
        );
    }
}
